class FileUrl {
  /**
   * Constructor
   */
  constructor() {
    this.baseUrl = process.env.VUE_APP_FILE_URL;
  }

  /**
   * Get image url
   */
  url(path) {
    let concat = process.env.NODE_ENV === 'development' ? 'dev' : 'dcomcy';
    return `${this.baseUrl}${concat}${path}`;
  }
}

export default new FileUrl();
